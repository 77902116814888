.header-banner {
    background-color: $off-gray;
    position: relative;
    overflow: hidden;
    height: calc(100vh - 80px);
    @media (max-width: $screen-sm-max) {
        height: auto;
    }
    * {
        text-align: left;
    }
    &__item {
        position: relative;
        height: 100%;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            @media (max-width: $screen-xs-max) {
                background-color: rgba(0, 0, 0, 0.7);
                z-index: 1;
            }
        }
    }
    &__data {
        position: absolute;
        bottom: 45px;
        left: 0;
        right: 0;
        color: $white;
        @media (max-width: $screen-xs-max) {
            // z-index: 11;
            z-index: 9;
            margin: 200px 0 0!important;
        }
        .small-text {
            font-size: $font-xs;
            font-weight: $font-semibold;
            letter-spacing: 2px;
            text-transform: uppercase;
        }
        .main-text {
            padding-right: 50%;
            margin-top: 10px;
            font-weight: $font-light;
            line-height: 1.3;
        }
        @media (max-width: $screen-sm-max) {
            position: relative;
            bottom: 0;
            margin: 45px 0;
            .main-text {
                padding-right: 10%;
                font-size: $font-lg;
                line-height: 1.1;
                min-height: 165px;
            }
        }
    }
    &__img {
        width: 100%;
        // width: auto;
        // height: auto;
        height: calc(100vh - 80px);
        min-width: 100%;
        min-height: 100%;
        @media (max-width: $screen-sm-max) {
            position: absolute;
            min-height: 100%;
            min-width: 100%;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    &__parteners {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        padding-right: inherit;
        padding-left: inherit;
        text-align: right;
        @media (max-width: $screen-md-max) {
            text-align: center;
            img {
                width: 50px;
                height: 50px;
                margin: 0 2px;
            }
        }
    }
    .slick-dots {
        text-align: center!important;
    }
}

.header-banner--slider {
    .header-banner__data {
        top: 50%;
        transform: translateY(-50%);
        bottom: unset;
    }
    @media (max-width: $screen-sm-max) {
        top: unset;
        transform: none;
    }
}

.header-banner--slider,
.clients-slider,
.testimonials-slider,
.section-slider {
    .header-banner__data {
        bottom: 100px;
        @media (max-width: $screen-sm-max) {
            bottom: 0;
            margin: 45px 0 100px;
        }
    }
    .get-more {
        margin: 60px 0 5px;
        @media (max-width: $screen-sm-max) {
            margin: 30px 0 5px;
        }
    }
    .slick-dots {
        text-align: left;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 45px;
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
        li {
            width: 60px;
            height: 2px;
            margin-left: 0;
            margin-right: 4px;
            background-color: $white;
            opacity: 0.4;
            @media (max-width: $screen-sm-max) {
                height: 10px;
            }
            @media (max-width: $screen-xs-max) {
                height: 20px;
                width: 20px;
            }
            &.slick-active {
                opacity: 1;
            }
            button {
                display: none;
            }
        }
    }
}

.clients-slider,
.testimonials-slider {
    padding-bottom: 30px;
    .slick-dots {
        text-align: center;
        bottom: 0;
    }
}

.section-slider {
    padding-bottom: 50px;
    .slick-slide {
        margin-right: 20px;
    }
    .slick-dots {
        text-align: center;
        bottom: 0;
        li {
            background-color: $medium-gray;
        }
    }
}

.service-inner-banner {
    @media (min-width: $screen-md-min) {
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: contain;
        height: 100%;
    }
    @media (max-width: $screen-sm-max) {
        padding: 0;
        &+.header-banner__data {
            margin: 0;
            padding: 45px 0;
        }
    }
    .icon {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        transform: translateY(90%);
        text-align: right;
        svg {
            overflow: visible;
            fill: transparentize($white, 0.8);
            transform-origin: calc(100% - 10px) 60%;
            transform: scale(8);
        }
    }
}