.alm-btn-wrap{
	@extend .text-center;
}

.alm-load-more-btn.more{
	margin: $c-padding auto;
	@extend .btn;
	@extend .btn-custom;
	@extend .btn-custom;
	@extend .xlg-btn;
	@extend .gbg-purple;
	&.done{
		display: none;
	}
}

