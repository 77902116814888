.tags {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    a {
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 10px;
        background-color: $white;
        padding: 20px 30px;
        font-weight: $font-bold;
        border-radius: 5px;
    }
}