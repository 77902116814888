.mCSB_container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    top: 0;
    bottom: auto;
    .all-services & {
        top: auto;
        bottom: 0 !important;
        right: 100px !important;
        left: 10px !important;
    }
}

.mCSB_horizontal.mCSB_inside {
    >.mCSB_container {
        margin-bottom: 0;
        ul.cards-tabs {
            margin-top: 20px;
        }
    }
    .all-services & {
        >.mCSB_container {
            // margin-bottom: 0;
            // margin-top: 20px;
            margin-bottom: 20px;
            margin-top: 0;
        }
    }
}

//////////
.mCSB_scrollTools {
    // display: none!important;
    .all-services & {
        display: block !important;
    }
}

/////////
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    // height: 5px;
    height: 10px;
    margin: 7px auto;
    background-color: $light-blue;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    // height: 5px;
    height: 10px;
    background-color: $light-gray;
}

.scrollbar-arrows {
    position: absolute;
    right: 10px;
    bottom: -10px;
    .prev-arrow,
    .next-arrow {
        display: inline-block;
    }
    .prev-arrow {
        margin-right: 20px;
    }
    .next-arrow {
        svg {
            transform: rotate(180deg);
        }
    }
}

.ico-blue {
    fill: #299be7;
    stroke: #299be7;
}