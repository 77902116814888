.thumbnail-animated-group {
    position: relative;
    .thumbnail {
        padding: 0;
        padding-bottom: 90px;
        margin-bottom: 0;
        border: none;
        overflow: hidden;
        border-radius: 0;
        outline: none;
        >img {
            width: 100%;
        }
    }
    .caption {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 30px 20px 20px;
        transition: all 0.5s ease-in-out;
        @media (min-width: $screen-md-min) {
            transform: translateY(100%) translateY(-90px);
        }
        @media (max-width: $screen-md-max) {
            height: 170px;
        }
        &__title {
            margin: 0 0 20px;
            color: $white;
            // white-space: nowrap;
            width: 100%;
            // overflow: hidden;
            // text-overflow: ellipsis;
        }
        &__brief {
            margin: 0 0 20px;
            color: transparentize( $white, 0.4);
            transition: all 1s;
            @media (min-width: $screen-md-min) {
                opacity: 0;
                transform: translateY(90px);
            }
            @media (max-width: $screen-md-max) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        .get-more {
            transition: all 1s;
            @media (min-width: $screen-md-min) {
                opacity: 0;
                transform: translateY(90px);
            }
        }
        /* Shown Caption on Mouseenter*/
        &.shown {
            transform: translateY(0);
            .caption__brief,
            .get-more {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
    &.colored {
        .caption {
            &__title {
                color: $light-blue;
            }
            &__brief {
                color: $dark-blue;
            }
            .get-more {
                a {
                    color: $dark-blue;
                }
            }
        }
    }
}