.logo-img-contain {
  padding: 10px 30px;
  text-align: center;
  @media (max-width: $screen-sm-max) {
    padding: 20px 25px;
  }
  @media (max-width: $screen-xs-max) {
    // padding: 20px 30%;
  }
  img {
    position: relative;
    height: auto;
    max-width: 100%;
    margin: auto;
    margin-bottom: 30px;
  }
}
