/* Layout Main Structure classes */

.main-header {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    position: relative;
    z-index: 10;
}

.main-content {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    min-height: calc(100vh - (250px/* Footer Height*/
    + 80px/* Header Height*/
    ));
}

.main-footer {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.component {
    background-color: $white;
    padding-top: 0;
    padding-bottom: 0;
    &--dark-bg {
        background-color: $dark-gray;
    }
    &--gradient-white {
        background-image: linear-gradient(to bottom, rgba(243, 243, 243, 0), #f3f3f3 10%, #ffffff);
    }
    &--space-bottom-lg {
        padding-bottom: 100px;
    }
    &__title {
        margin: 0 0 25px;
        &--white {
            color: $white;
        }
        &--colored {
            @extend .blueGradientText;
        }
        &--subtitled {
            margin: 0 0 10px;
        }
    }
    &__brief {
        max-width: 620px;
        font-size: $font-md;
        font-weight: $font-light;
        color: $black;
        line-height: 1.5;
        // text-align: center;
        text-align: justify;
        margin: 25px auto 40px;
    }
}

.dark-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 10;
    background-color: transparentize( $black, 0.4);
    transition: all 0.3s;
    display: none;
    &.opened {
        opacity: 1;
    }
}

//career form hint
.hint {
    @extend .blueGradientText;
    margin-top: 5px;
    display: block;
}