.slick-dotted.slick-slider,
.slick-slider {
    margin: 0;
    &.testimonials-slider {
        margin-top: -50px;
    }
}

.header-banner--slider {
    .slick-dots {
        @media (min-width: $screen-lg-min) {
            width: 1200px;
        }
    }
}

.slick-list,
.slick-track,
.slick-initialized .slick-slide {
    height: 100%;
}

.slick-prev,
.slick-next {
    z-index: 20;
    width: 64px;
    height: 64px;
    // top: calc(50% - 45px);
    &:before {
        display: none;
    }
    @media (max-width: $screen-sm-max) {
        transform: scale(0.5);
        top: 10px;
    }
}

.slick-prev {
    left: 20px;
    @media (max-width: $screen-sm-max) {
        left: 10px;
    }
}

.slick-next {
    right: 20px;
    @media (max-width: $screen-sm-max) {
        right: 10px;
    }
}

.latest-solutions-slider {
    .slick-slide {
        padding: 0;
        img {
            width: 100%;
            height: auto;
        }
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparentize($black, 0.2);
            opacity: 0;
            transition: all 0.1s linear;
            z-index: 1;
            @media (max-width: $screen-xs-max) {
                opacity: 1;
            }
        }
        &:hover {
            &:before {
                opacity: 1;
            }
            .overlay-content {
                opacity: 1;
            }
        }
        .overlay-content {
            padding: 20px 40px;
            transition: all 0.1s linear;
            color: $white;
        }
    }
    .col-md-3 {
        padding: 0;
    }
}

.cards-slider {
    img {
        opacity: 0.5;
    }
    button.slick-prev {
        img {
            transform: rotate(180deg);
        }
    }
}