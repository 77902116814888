.main-footer {
    background-color: $off-white;
    min-height: 250px;
    position: relative;
    text-align: center;
    &__content {
        position: relative;
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: $font-xs;
        color: $medium-gray;
        font-weight: $font-semibold;
        @media (max-width: $screen-md-max) {
            padding-top: 0;
        }
    }
    &__parteners {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: inherit;
        padding-left: inherit;
        text-align: right;
        @media (max-width: $screen-md-max) {
            position: relative;
            text-align: center;
            // img {
            //     width: 70px;
            //     height: 70px;
            //     margin: 0 5px;
            // }
        }
        @media (max-width: $screen-xs-max) {
            img {
                width: 125px;
            }
        }
    }
    &__nav {
        ul {
            margin: 35px 0;
            padding: 0;
        }
        li {
            list-style: none;
            display: inline-block;
            letter-spacing: 2.2px;
            text-transform: uppercase;
            margin: 0 16px;
            line-height: 1.7;
            &:hover {
                color: $dark-gray;
            }
            a {
                color: inherit;
            }
        }
    }
    &__social-icon {
        fill: $medium-gray;
        transition: all 0.3s;
        &:hover {
            fill: $dark-gray;
        }
    }
}