.testimonials {
    .testimonials-title {
        text-transform: uppercase;
        color: #6b6b6b;
        text-align: center;
        opacity: 0.5;
    }
    .testimonials-slider {
        h5 {
            text-align: center;
            color: $white;
            text-transform: uppercase;
            font-weight: $font-bold;
            letter-spacing: 2px;
            margin-bottom: 40px;
        }
        p {
            font-size: 20px;
            text-align: center;
            color: #6B6B6B;
        }
        .slick-slide {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 35px;
                left: 0;
                width: 96px;
                height: 88px;
                background-image: url('../images/about/comma.png');
                transform: rotate(180deg);
            }
            &:after {
                content: '';
                position: absolute;
                top: 35px;
                right: 0;
                width: 96px;
                height: 88px;
                background-image: url('../images/about/comma.png');
            }
        }
    }
}