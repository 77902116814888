// 404
.section-404 {
    padding: 4% 0;
}

.page-404 {
    text-align: center;
    padding: 40px 0px;
    background-image: url('../images/404/404_bg.svg');
    background-repeat: no-repeat;
    background-position: 70px 0;
    @media (max-width: $screen-sm-max) {
        background-position: 0 0;
    }
    h2 {
        font-size: 140px;
        font-weight: $font-regular;
        letter-spacing: 10px;
        @media (max-width: $screen-sm-max) {
            font-size: 100px;
            letter-spacing: 5px;
        }
    }
    p {
        font-weight: $font-light;
        font-size: 24px;
        max-width: 300px;
        margin: auto;
        margin-bottom: 40px;
        @media (max-width: $screen-sm-max) {
            font-size: 18px;
        }
    }
}