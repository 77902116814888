.c-padding-top {
    padding-top: $c-padding;
    @media (max-width: $screen-sm-max) {
        padding-top: $c-padding-responsive;
    }
}

.c-padding-bottom {
    padding-bottom: $c-padding;
    @media (max-width: $screen-sm-max) {
        padding-bottom: $c-padding-responsive;
    }
}

.c-padding-side {
    padding-right: $c-padding;
    padding-left: $c-padding;
    @media (max-width: $screen-sm-max) {
        padding-left: $c-padding-responsive;
        padding-right: $c-padding-responsive;
    }
}

.c-padding {
    @extend .c-padding-top;
    @extend .c-padding-bottom;
}

.get-more a {
    font-size: $font-xs;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    color: $white;
    display: block;
    font-weight: $font-semibold;
    &--colored {
        color: $dark-blue;
    }
    &:hover {
        opacity: 0.8;
    }
}

&.left-title {
    padding-left: 40px;
    padding-right: 40px;
}

.flex-wrapper-sm {
    @media (min-width: $screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        body.safari & {
            &::after,
            &::before {
                display: none;
            }
        }
        .flex-item {
            display: flex;
        }
    }
    .flex-item-md {
        @media (max-width: $screen-md-max) and (min-width: $screen-sm-min) {
            display: flex;
            -webkit-box-pack: center;
            -webkit-box-orient: vertical;
        }
    }
}

.flex-wrapper-md {
    @media (min-width: $screen-md-min) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        body.safari & {
            &::after,
            &::before {
                display: none;
            }
        }
        .flex-item {
            display: flex;
        }
    }
}

.flex-center {
    justify-content: center;
    align-content: center;
    align-items: center;
}

.flex-item-1 {
    display: flex;
    flex: 1;
}

.blueGradientText {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(93deg, $light-blue, $dark-blue);
    .ie11 & {
        color: $dark-blue;
        background: none;
    }
}

.col-5-custom {
    @media (min-width: $screen-md-min) {
        width: 20%;
    }
}

.no-gutter>[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.no-gutter-col {
    padding-right: 0;
    padding-left: 0;
}


/* Colors > */

*[class*='gbg-'] {
    transition: transform 0.3s ease-out;
}

.gbg-purple {
    background: $light-purple linear-gradient(to bottom, $light-purple, $dark-purple);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-purple linear-gradient(to left, $light-purple, $dark-purple);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(to bottom, transparentize($light-purple, 0.2), transparentize($dark-purple, 0.2));
    }
}

.gbg-blue {
    background: $light-blue linear-gradient(to bottom, $light-blue, $dark-blue);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-blue linear-gradient(to right, $light-blue, $dark-blue);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(to bottom, transparentize($light-blue, 0.2), transparentize($dark-blue, 0.2));
    }
}

.gbg-cyan {
    background: $light-cyan linear-gradient(to bottom, $light-cyan, $dark-cyan);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-cyan linear-gradient(to right, $light-cyan, $dark-cyan);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(to bottom, transparentize($light-cyan, 0.2), transparentize($dark-cyan, 0.2));
    }
}

.gbg-orange {
    background: $light-orange linear-gradient(to bottom, $light-orange, $dark-orange);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-orange linear-gradient(to right, $light-orange, $dark-orange);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(to bottom, transparentize($light-orange, 0.2), transparentize($dark-orange, 0.2));
    }
}

.gbg-green {
    background: $light-green linear-gradient(to bottom, $light-green, $dark-green);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-green linear-gradient(to right, $light-green, $dark-green);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(to bottom, transparentize($light-green, 0.2), transparentize($dark-green, 0.2));
    }
}

.gbg-yellow {
    background: $light-yellow linear-gradient(to bottom, $light-yellow, $dark-yellow);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-yellow linear-gradient(to right, $light-yellow, $dark-yellow);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(to bottom, transparentize($light-yellow, 0.2), transparentize($dark-yellow, 0.2));
    }
}

.gbg-cblue {
    background: $light-cblue linear-gradient(to bottom, $light-cblue, $dark-cblue);
    @at-root button#{&},
    &.btn,
    {
        &,
        &.btn:active,
        &.btn:focus {
            background: $light-cblue linear-gradient(right, $light-cblue, $dark-cblue);
            &:after {
                content: " →";
                font-size: 0;
                transition: all 0.3s;
            }
        }
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 6px 0 transparentize($black, 0.85);
            &:after {
                font-size: inherit;
            }
        }
    }
    &--transparent {
        background: linear-gradient(134deg, transparentize($light-cblue, 0.2), transparentize($dark-cblue, 0.2));
    }
}

.img-responsive {
    margin-left: auto;
    margin-right: auto;
}

.relative {
    position: relative;
}

.lead {
    margin: 0;
    font-weight: $font-regular;
    font-size: 20px;
}

.features {
    .each-feature {
        padding-bottom: 20px;
        p {
            line-height: 1.75;
            font-weight: $font-light;
        }
    }
}

.column-txt {
    font-weight: $font-light;
    line-height: 1.75;
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2;
    column-gap: 40px;
    text-align: justify;
    @media (max-width: $screen-xs-max) {
        -webkit-column-count: 1;
        /* Chrome, Safari, Opera */
        -moz-column-count: 1;
        /* Firefox */
        column-count: 1;
    }
}

.gray-icon {
    fill: $medium-gray;
    &:hover {
        fill: $dark-gray;
    }
}

.gray-bg {
    background-color: $light-off-white;
}

.d-gray-bg {
    background-color: $dark-gray;
}

.black-bg {
    background-color: $black;
}

.block-padding {
    padding-top: 40px;
    padding-bottom: 40px;
}

.full-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.row-extraPadding {
    margin-left: -20px;
    margin-right: -20px;
    *[class*=col-] {
        padding-right: 20px;
        padding-left: 20px;
    }
}