.site-map {
    @extend .text-capitalize;
    ul {
        @extend .list-unstyled;
        &.levels {
            margin-top: 30px;
            li {
                a {
                    display: block;
                    margin-bottom: 10px;
                }
                >a {
                    color: #000;
                }
                >ul {
                    padding-left: 30px;
                    li {
                        a {
                            color: #8A8A8A;
                        }
                    }
                }
            }
        }
    }
    a.title,
    a.subtitle {
        @extend .component__title--colored;
        font-size: 24px;
        display: block;
    }
    .subtitle {
        margin-top: 50px;
    }
}

.case-study {
    margin: 10px 0;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-items: stretch;
    @media (max-width: $screen-xs-max) {
        display: block;
    }
}