.service-card {
  display: block;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  padding: 36px 10px 45px;
  margin: 20px 0;
  font-family: "Comfortaa";
  box-shadow: -5px 5px 10px 0 transparentize($black, 0.85);
  transition: all 0.2s;
  color: $white;
  transition: margin 0s;
  &:hover {
    color: transparentize($white, 0.3);
  }
  &__icon {
    fill: $white;
    stroke: $white;
    width: 100%;
    height: 132px;
    margin-bottom: 30px;
    position: relative;
    top: 0;
    transition: all 0.5s;
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &__title {
    color: inherit;
    font-size: 18px;
        transition: all 0.5s;
  }
  &[role=tab] {
    &,
    &:active {
      color: $white;
    }
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      .service-card__title {
        font-size: $font-xs;
      }
    }
    @media (max-width: $screen-sm-max) {
      height: auto;
      display: flex;
      padding: 40px;
      margin: 5px 0;
      align-items: center;
      .service-card__icon {
        margin: 0;
        height: auto;
        width: 10%;
        svg {
          width: 100%;
        }
      }
      .service-card__title {
        padding: 0 30px;
      }
    }
    @media (max-width: $screen-xs-max) {
      height: auto;
      display: flex;
      padding: 25px;
      margin: 5px 0;
      align-items: center;
      .service-card__icon {
        width: 12%;
        svg {
          width: 40%;
        }
      }
      .service-card__title {
        padding: 0 10px;
        font-size: $font-xs;
      }
    }
    @media (max-width: 480px) {
      padding: 15px;
      .service-card__icon {
        width: 20%;
      }
    }
  }
  .active & {
    @media (min-width: $screen-md-min) {
      margin-bottom: -21px;
      border: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-image: none;
      .ie11 & {
        margin-bottom: -22px;
      }
      .service-card__icon {
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .service-card__title {
         transform: translateY(60px);
      }
    }
  }
}

.cards-tabs-content {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 40px;
}

.each-service {
  min-width: 300px;
  display: flex;
}

.cards-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
