// map
.connect-map {
    padding: 40px 0;
}

.marker-tooltip {
    position: absolute;
    display: none;
}

.numbers {
    margin-top: 20px;
    text-align: center;
    @media (max-width: $screen-xs-max) {
        margin-bottom: 70px;
    }
    .number {
        display: block;
        min-height: 170px;
        font-size: 120px;
        font-weight: $font-bold;
        font-family: "Comfortaa";
        color: #4b4b4b;
    }
    h3 {
        color: $white;
        margin-top: -105px;
        text-transform: uppercase;
        @media (max-width: $screen-xs-max) {
            margin-bottom: 30px;
        }
    }
}

.countries {
    .countries-title {
        text-transform: uppercase;
        line-height: 42px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        @extend .blueGradientText;
    }
    p {
        color: $white;
    }
}

.marker-tooltip-content {
    background-color: $white;
    padding: 10px 15px;
    width: 220px;
    min-height: 75px;
    text-align: center;
    span {
        color: $light-blue;
        font-weight: $font-bold;
    }
    &:after {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 45%;
        width: 0px;
        height: 0px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 15px solid $white;
    }
    img {
        display: block;
        margin: auto;
    }
}

.marker {
    opacity: 0;
    .visible & {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: bounceInDown;
        animation-name: bounceInDown;
        opacity: 1;
    }
}

@-webkit-keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -800px, 0);
        transform: translate3d(0, -800px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -800px, 0);
        transform: translate3d(0, -800px, 0);
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 25px, 0);
        transform: translate3d(0, 25px, 0);
    }
    75% {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}