.overlay-content {
  opacity: 0;
  padding: 20px;
  width: 100%;
  transition: all 0.2s linear;
  z-index: 5;
  @media (max-width: $screen-sm-max) {
    opacity:1;
    z-index: 2;
  }
  &__title {
    color: $white;
    margin-top: 0;
    @media (max-width: $screen-xs-max) {
      font-size: $font-md;
    }
    a {
      color: $white;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__brief {
    font-size: $font-md;
    font-weight: $font-light;
    line-height: 1.45;
    color: $white;
    padding: 0 50px;
    @media (max-width: $screen-xs-max) {
      padding:0;
    }
  }
}

.box-overlay {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: all 0.2s linear;
    z-index: 1;
    @media (max-width: $screen-sm-max) {
      opacity: 1;
    }
  }
  &:hover {
    &.box-overlay:before,
    .overlay-content {
      opacity: 1;
      margin-top: 0;
    }
  }
}
