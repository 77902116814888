.main-header {
    .navbar {
        box-shadow: 0 5px 10px 0 transparentize($black, 0.95);
        height: 80px;
        position: relative;
        z-index: 20;
        &-collapse {
            position: relative;
            padding: 0;
            @media (max-width: $screen-sm-max) {
                background-color: $white;
                top: 13px;
                border: 1px solid $off-white;
                border-top: none;
            }
        }
        &__icon {
            fill: $medium-gray;
            &.clicked-icon {
                cursor: pointer;
            }
        }
        &-nav {
            margin-left: 0;
            margin-right: 0;
            >li {
                .dropdown-menu {
                    background-color: #F4F4F4;
                    border: 0;
                    border-radius: 0;
                    box-shadow: none;
                    top: 80px;
                    @media (max-width: $screen-sm-max) {
                        position: relative;
                        top: 0;
                        padding-top: 0;
                        display: block;
                        padding-left: 20px;
                        min-width: 100%;
                    }
                    >li {
                        margin: 0 15px;
                        &:last-child {
                            a {
                                border-bottom: 0;
                            }
                        }
                        >a {
                            color: $black;
                            font-weight: $font-semibold;
                            font-size: 14px;
                            padding: 10px;
                            background-color: transparent;
                            @media (max-width: $screen-sm-max) {
                                border-bottom: 0;
                            }
                            &:hover {
                                background-color: transparent;
                                @extend .blueGradientText;
                            }
                        }
                    }
                }
                >a {
                    padding-right: 20px;
                    padding-left: 20px;
                    &:hover {
                        @extend .blueGradientText;
                    }
                }
                &.navbar__searchButton a {
                    padding-right: 0px;
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
                @media (max-width: $screen-md-max) {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
            li.current_page_item,
            li.current-menu-parent {
                >a {
                    font-weight: $font-semibold;
                    @extend .blueGradientText;
                }
            }
            @media (min-width: $screen-md-min) {
                li>a {
                    // padding-bottom: 0;
                }
            }
        }
        &-header {
            position: relative;
            // z-index: 20;
            z-index: 26;
        }
        &-brand {
            // margin-right: 50px;
            height: auto;
            padding-top: 15px;
            padding-bottom: 0;
            @media (max-width: $screen-md-max) {
                margin-right: 20px;
            }
        }
        &-toggle {
            margin-bottom: 0;
        }
    }
    .search-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        margin: auto;
        display: none;
        z-index: 25;
        background-color: $white;
        padding-left: 150px;
        &__form {
            margin: 18px;
            .form-control {
                border: none;
                box-shadow: none;
                background: none;
                padding: 0 20px;
                font-size: $font-sm;
                color: $medium-gray;
            }
        }
        &__icon {
            position: absolute;
            left: 0;
            height: 18px;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: inherit;
        }
        &__close {
            position: absolute;
            right: 0;
            height: 18px;
            top: 0;
            bottom: 0;
            margin: auto;
            padding: inherit;
        }
        &:before {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; // opacity:0;
            z-index: -1;
            background-color: transparentize( $black, 0.4);
            transition: all 0.3s;
        }
    }
}

// show bootstrap dropdown on hover
.main-header .navbar-nav>li:hover .dropdown-menu {
    display: block;
}

.software-solutions-dropdown {
    width: 600px;
    padding: 30px 0;
    h4 {
        font-size: 18px;
        color: #A9A9A9;
        position: relative;
        margin-bottom: 30px;
        margin-top: 0;
        &:after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 70px;
            height: 2px;
            background-color: #A9A9A9;
        }
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            margin-bottom: 15px;
        }
        a {
            color: $black;
            font-weight: $font-semibold;
            font-size: 14px;
            &:hover {
                color: $light-blue;
            }
        }
    }
    .solution-industry {
        margin-top: 40px;
    }
    .each-solution {
        padding-left: 40px;
        margin-top: 20px;
        &:last-child {
            border-left: 1px solid #A9A9A9;
        }
    }
}