.img-holder {
    position: relative;
    overflow: hidden;
    img {
        @media (max-width: 1279px) {
            position: absolute;
            min-width: 100%;
            width: auto;
            height: auto;
            max-width: none;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        @media (max-width: $screen-xs-max) {
            position: relative;
            height: auto;
            width: 100%;
            top: 0;
            left: 0;
            transform: none;
        }
    }
    &:not(.img-holder--relative) {
        padding-bottom: 30%;
        img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            width: 100%;
        }
    }
    &--relative {
        img {
            position: relative;
            transform: none;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
        }
    }
}