// buttons
.btn-custom {
  width: 360px;
  height: 60px;
  border-radius: 8px;
  line-height: 45px;
  text-transform: uppercase;
  font-size: 13px;
  color: $white;
  letter-spacing: 2px;
  margin: 0 10px;
  @media (max-width: $screen-xs-max) {
    width: 280px;
    margin: 10px auto;
  }
  &:hover,
  &:focus {
    color: $white;
  }
  &.xlg-btn {
    @media (min-width: $screen-lg-min) {
      width: 400px;
    }
  }
}
