.thumbnail-normal-group {
    position: relative;
    .thumbnail {
        margin-bottom: 0;
        border: none;
        overflow: hidden;
        border-radius: 0;
        outline: none;
        >img {
            width: 100%;
            @media (max-width: $screen-xs-max) {
                width: 70%;
            }
        }
        .caption {
            padding: 35px 0px;
            &__title {
                margin: 0;
                color: $white;
                @extend .blueGradientText;
                // white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &__brief {
                margin: 20px 0 0;
                color: transparentize($dark-gray, 0.4);
                height: 65px;
                overflow: hidden;
                @media (max-width: $screen-sm-max) {
                    height: 75px;
                }
            }
            &__date {
                font-size: $font-xs;
                line-height: 1.69;
                letter-spacing: 2.2px;
                color: $medium-gray;
                margin-top: 15px;
            }
            &__table-data {
                margin: 0;
                width: 100%;
                tr {
                    border-bottom: 1px solid $light-gray;
                }
                td {
                    padding: 20px 0;
                    vertical-align: top;
                    &.name-cell {
                        font-size: $font-xs;
                        line-height: 1.69;
                        letter-spacing: 2.2px;
                        color: $medium-gray;
                        text-transform: uppercase;
                        padding-right: 20px;
                    }
                    &.value-cell {
                        color: transparentize( $black, 0.3);
                        line-height: 1.38;
                    }
                }
            }
            .get-more {
                margin: 20px 0 0;
                a {
                    color: $dark-blue;
                }
            }
        }
    }
    .full-cards {
        .thumbnail {
            padding: 0;
            .caption {
                padding: 35px 20px;
            }
        }
        &:not(.no-hover) .thumbnail:hover {
            @media (min-width: $screen-md-min) {
                // background: $black;
                background-color: $light-gray;
                .caption {
                    &__title,
                    &__brief,
                    &__table-data .value-cell,
                    .get-more a {
                        color: $white;
                        background: none;
                        -webkit-text-fill-color: inherit;
                    }
                }
            }
        }
    }
}