.modal-content {
  box-shadow: none;
  border: none;
  padding: 50px;
  @media (max-width: $screen-xs-max) {
    padding:20px;
  }
}

.modal-header {
  padding: 0;
  border-bottom: none;
  .close {
    font-size: 60px;
    font-weight: $font-light;
    opacity: 0.5;
    margin-top: -20px;
    @media (max-width: $screen-xs-max) {
      font-size: 42px;
      margin-top: -10px;
    }
  }
}

.modal-body {
  form .btn-custom {
    @media (max-width: $screen-xs-max) {
      width: 280px;
    }
  }
}
