$form-font-size:18px;
$form-label-color:#777777;
$form-btn-bg: #bbbbbb;
// forms
.form-group {
    position: relative;
    margin-bottom: 22px;
    label {
        position: absolute;
        top: 8px;
        left: 0;
        font-weight: $font-light;
        font-size: $form-font-size;
        color: $form-label-color;
        &.error {
            color: $red;
        }
        &.success {
            color: #1ea500;
        }
    }
    &.focused label {
        color: $dark-blue;
    }
    use {
        transition: 0.2s;
    }
    use:not(.incomplete-icon) {
        opacity: 0;
    }
    br {
        display: none;
    }
}

.form-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid currentColor;
    border-radius: 0;
    font-size: $form-font-size;
    font-weight: $font-light;
    padding-left: 100px;
    padding-right: 20px;
    text-align: left;
    color: #d8d8d8;
    .events-form & {
        padding-left: 130px;
    }
    &~.form-icon use {
        position: relative;
    }
    &:focus {
        color: $dark-blue !important;
        &~.form-icon use {
            opacity: 0 !important;
            z-index: 0 !important;
            &.progress-icon {
                opacity: 1 !important;
                z-index: 2 !important;
            }
        }
    }
    &.success {
        color: #1ea500;
        &~.form-icon use {
            opacity: 0;
            z-index: 0;
            &.complete-icon {
                opacity: 1;
                z-index: 2;
            }
        }
    }
    &.parsley-error {
        color: $red;
        &~.form-icon use {
            opacity: 0;
            z-index: 0;
            &.error-icon {
                opacity: 1;
                z-index: 2;
            }
        }
    }
}

.form-icon {
    position: absolute;
    top: 15px;
    right: 0;
}

form {
    .btn-custom {
        background-color: $form-btn-bg;
        display: block;
        margin: 50px auto 0 auto;
        color: $white;
    }
}

.file-input {
    position: relative;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 0;
    @media (max-width: $screen-xs-max) {
        overflow: hidden;
        height: 38px;
    }
    &.focused {
        border-color: #66afe9;
    }
    input {
        position: relative;
        z-index: 1;
        opacity: 0;
    }
    &:after {
        content: attr(data-placeholder);
        position: absolute;
        font-size: $form-font-size;
        font-weight: $font-light;
        color: $medium-gray;
        top: 8px;
        left: 100px;
        z-index: 0;
    }
}

ul.parsley-errors-list {
    padding: 0;
    li {
        text-align: left;
        line-height: 1.5;
        font-size: $font-xs;
        list-style: none;
        color: $red;
    }
}