$facebook-bg:#3b5998;
$twitter-bg:#326ada;
$linkedin-bg:#0077b5;
$share-bg: linear-gradient(128deg, #de6161, #2657eb);

.social-icons {
  width: 120px;
  padding: 0;
  margin: 0;
  list-style: none;
  .social-item {
    border: 1px solid #d8d8d8;
    width: 60px;
    height: 60px;
    display: table;
    float: left;
    margin-right: -1px;
    margin-bottom: -1px;
    a {
      display: table-cell;
      vertical-align: middle;
    }
    svg {
      fill: $medium-gray;
      vertical-align: middle;
      transition: all 0.3s ease;
      margin-top:0;
    }
    .details{
      display: block;
      font-size: 0;
      color: $white;
      transition: all 0.3s ease;
    }
    @media (min-width: $screen-sm-max){
      &:hover{
        &.facebook{
          background: $facebook-bg;
        }
        &.twitter{
          background: $twitter-bg;        
        }
        &.linkedin{
          background: $linkedin-bg;        
        }
        &.share{
          background: $share-bg;        
        }
        svg{
          fill: $white;
          margin-top:8px;
        }
        .details{
          font-size: 14px;
        }
      }
    }
    @media (max-width: $screen-sm-max){
      border:none;
      &.facebook{
        background: $facebook-bg;
      }
      &.twitter{
        background: $twitter-bg;        
      }
      &.linkedin{
        background: $linkedin-bg;        
      }
      &.share{
        background: $share-bg;        
      }
      svg{
        fill: $white;
        margin-top:8px;
      }
      .details{
        font-size: 14px;
      }
    }
  }
  @media (max-width: $screen-xs-max){
    margin:0px auto 20px;
    width: 240px;
    &:after{
      content:"";
      display: table;
      clear:both;
    } 
    li{
      display: inline-block;
      width:auto;
      height:auto;
    }
  }
}
