.timeline {
    .timeline-container {
        @media (min-width: $screen-lg-min) {
            width: 1000px;
        }
    }
    .each-event {
        height: 250px;
        background-clip: content-box;
        margin-bottom: 20px;
        text-align: center;
        overflow: hidden;
        .bullet {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            background-color: #bbb;
            border-radius: 5px;
            margin-top: -6px;
            margin-left: -6px;
        }
        .each-time {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            margin: auto;
            text-align: center;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: -14px;
                left: 42px;
                width: 0px;
                height: 0px;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 15px solid #1c2993;
            }
            &.gbg-orange {
                &:after {
                    border-top: 15px solid #ee7361;
                }
            }
            &.gbg-blue {
                &:after {
                    border-top: 15px solid #266be9;
                }
            }
            &.gbg-cyan {
                &:after {
                    border-top: 15px solid #23a0cb;
                }
            }
            &.gbg-purple {
                &:after {
                    border-top: 15px solid #735bb1;
                }
            }
            &.gbg-green {
                &:after {
                    border-top: 15px solid #15a085;
                }
            }
            &.gbg-yellow {
                &:after {
                    border-top: 15px solid #e97400;
                }
            }
        }
        .month {
            display: block;
            padding-top: 25px;
            color: $white;
            font-size: 12px;
        }
        .year {
            color: $white;
            font-size: 24px;
        }
        h4 {
            margin-top: 50px;
            font-size: 16px;
            color: rgba(34, 34, 34, 0.6);
        }
        &:after {
            @media (min-width: $screen-lg-min) {
                content: '';
                position: absolute;
                bottom: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: #bbb;
            }
        }
    }
    .event-row,
    .event-row {
        position: relative;
    }
    .row:first-child {
        .each-event:first-child {
            &:after {
                left: 50%;
                width: 50%;
            }
        }
        .event-row {
            &:before {
                content: none!important;
            }
        }
    }
    .row:last-child {
        .each-event:last-child {
            &:after {
                width: 50%;
            }
        }
    }
    .row:nth-child(odd) {
        .event-row {
            &:before {
                @media (min-width: $screen-lg-min) {
                    content: '';
                    position: absolute;
                    left: -130px;
                    top: -147px;
                    width: 300px;
                    height: 272px;
                    border-radius: 300px 0 0 300px;
                    border: 2px solid #bbb;
                    border-right: 0;
                }
            }
        }
    }
    .row:nth-child(even) {
        .event-row {
            &:before {
                @media (min-width: $screen-lg-min) {
                    content: '';
                    position: absolute;
                    right: -130px;
                    top: -147px;
                    width: 300px;
                    height: 272px;
                    border-radius: 0 300px 300px 0;
                    border: 2px solid #bbb;
                    border-left: 0;
                }
            }
        }
        .each-event:last-child {
            &:after {
                left: 50%;
                width: 50%;
            }
        }
        [class*='col-'] {
            @media (min-width: $screen-lg-min) {
                float: right;
            }
        }
    }
}