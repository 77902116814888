body {
    overflow-x: hidden;
}

body.modal-open {
    overflow: hidden;
}

* {
    outline: none !important;
    main & {
        @media (max-width: $screen-xs-max) {
            text-align: center;
        }
    }
}

input {
    box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Comfortaa";
    font-weight: $font-regular;
}

ul[role=tablist] {
    padding: 0;
}

// p[class$=__brief],
// p.lead {
//     text-align: justify;
// }
p.lead,
p.column-txt {
    text-align: justify;
}

@media (max-width: $screen-sm-max) {
    h2 {
        font-size: $font-lg;
    }
    p[class$=__brief] {
        font-size: $font-sm;
    }
}

@media (min-width: $screen-md-min) {
    img {
        vertical-align: bottom;
    }
}

ul.custom-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        font-size: 18px;
        margin-bottom: 20px;
        padding-left: 15px;
        &::before {
            content: '';
            display: inline-block;
            width: 5px;
            border-radius: 5px;
            height: 5px;
            vertical-align: middle;
            background-color: $dark-blue;
            position: relative;
            left: -15px;
        }
    }
}

.h-100 {
    height: 100%;
}