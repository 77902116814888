.search-header {
  background-color: $light-off-white;
  margin-bottom: 50px;
}

.search-title {
  margin: 80px 0;
  h2 {
    margin-bottom: 10px;
  }
  h3 {
    margin-top: 10px;
    font-weight: $font-light;
    color: $medium-gray;
  }
}

.search-result {
  padding-bottom: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
  h3 {
    font-weight: $font-light;
  }
  .search-txt {
    color: $dark-cblue;
    font-weight: $font-regular;
  }
  p {
    overflow: hidden;
    font-weight: 300;
    line-height: 1.75;
  }
  a {
    margin-top: 15px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 2.2px;
    font-weight: $font-semibold;
    color: $off-gray;
  }
}
