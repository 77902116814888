// Typography
$font-family-base: 'Montserrat';
$font-size-base:16px;
$text-color: $black;
$link-hover-decoration: none;
$font-size-h1:40px;
$font-size-h2:36px;
$font-size-h3:24px;
$font-size-h4:20px;
$font-size-h5:;
$font-size-h6:;
// components
$border-radius-base:8px;
// Grid System
$grid-gutter-width: 20px;
$grid-float-breakpoint: 992px;
$container-lg: 1200px;
// thumbnail
$thumbnail-border-radius: 5px;
// modals
$modal-lg: 1000px;
// tabs
$nav-tabs-border-color: transparent;
$nav-tabs-link-hover-border-color:transparent;
$nav-tabs-active-link-hover-bg:transparent;
$nav-tabs-active-link-hover-border-color:transparent;
// Navbar
$navbar-height: 80px;
$navbar-margin-bottom: 0;
$navbar-border-radius: 0;
$navbar-default-bg: $white;
$navbar-default-link-color: $medium-gray;
$navbar-default-link-hover-color: $dark-gray;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-bg: transparent;
//buttons
$btn-border-radius-base:5px;
$btn-default-color: $medium-gray;
$btn-danger-color: $white;
//$btn-danger-bg: $red;
//$btn-danger-border: $red;
$btn-info-color: $white;
$btn-info-bg: transparent;
$btn-info-border: $white;
$btn-primary-color: $white;
$btn-primary-bg: $dark-gray;
$btn-primary-border: $dark-gray;
//forms
$input-bg: #f4f4f4;
$input-height-base: 45px;