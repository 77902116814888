.faq {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d8d8d8;
  h3 {
    color: $dark-cblue;
    margin-top:0;
  }
  p{
    font-weight: 300;
    line-height: 1.75;
    font-size: $font-sm;
  	margin:0;
  }
}