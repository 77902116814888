/* Fonts */

@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700');

/* variables */

@import "global/variables";
@import "global/overrides";

/* bower:scss */

@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "bower_components/slick-carousel/slick/slick.scss";
@import "bower_components/slick-carousel/slick/slick-theme.scss";
@import "bower_components/bootstrap-select/sass/bootstrap-select";
@import "bower_components/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.min";

/* Layout & Theme */

@import "global/base";
@import "layout/layout";
@import "layout/custom-common";
@import "layout/header-nav";
@import "layout/header-banner";
@import "layout/footer";

/* Components */

@import "components/buttons";
@import "components/forms";
@import "components/services-cards";
@import "components/thumbnail-animated";
@import "components/thumbnail-normal";
@import "components/squared-text";
@import "components/img-holder";
@import "components/logo-container";
@import "components/social-icons";
@import "components/circles";
@import "components/modal";
@import "components/search";
@import "components/faq";
@import "components/tags";
@import "components/timeline";
@import "components/testimonials";
@import "components/overlay-box";
@import "components/404";
@import "components/map";
@import "components/site-map";

/* Vendors Override */

@import "vendors/slick-slider";
@import "vendors/wp-load-more";
@import "vendors/custom-scroll";