// colors
$white: #ffffff;
$black: #000000;
$red: #D0021B;
$light-off-white: #fafafa;
$off-white: #f4f4f4;
$light-gray: #d8d8d8;
$medium-gray: #999999;
$off-gray: #666666;
$dark-gray: #222222;
$light-purple: #9c5d92;
$dark-purple: #735bb1;
$light-blue: #299be7;
$dark-blue: #266be9;
$light-green: #9cbd5b;
$dark-green: #15a085;
$light-orange: #f3982c;
$dark-orange: #ee7361;
$light-cyan: #1aa0a2;
$dark-cyan: #23a0cb;
$light-cblue: #9d7bee;
$dark-cblue: #1c2993;
$light-yellow: #ffce00;
$dark-yellow: #e97400;
// spacing
$c-padding: 70px;
$c-padding-responsive: 30px;
// Font Sizes
$font-xs: 13px;
$font-sm: 18px;
$font-md: 21px;
$font-lg: 30px;
// Font Weights
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;