.circles {
    position: relative;
    padding: 100px;
    text-align: center;
    @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
        padding: 60px;
    }
    @media (max-width: $screen-sm-max) {
        padding: 0 10px;
    }
    &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background: linear-gradient(to bottom, $white, transparent, $white);
    }
}

#ballsCanvas {
    @media (max-width: $screen-sm-max) {
        display: none;
    }
}

.circles-shape {
    border-radius: 50%;
    padding: 60px;
    display: inline-block;
    width: 400px;
    height: 400px;
    position: relative;
    z-index: 15;
    transition: all 1s ease-out;
    @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
        width: 300px;
        height: 300px;
        padding: 60px;
    }
    @media (min-width: $screen-md-min) {
        opacity: 0;
        &--left {
            transform: translateX(-100px);
            &.viewed {
                opacity: 1;
                transform: translateX(30px);
            }
        }
        &--right {
            transform: translateX(100px);
            &.viewed {
                opacity: 1;
                transform: translateX(-30px);
            }
        }
    }
    @media (max-width: $screen-sm-max) {
        opacity: 0;
        &--left {
            transform: translateY(-30px);
            &.viewed {
                opacity: 1;
                transform: translateY(25px);
            }
        }
        &--right {
            transform: translateY(30px);
            &.viewed {
                opacity: 1;
                transform: translateY(-25px);
            }
        }
    }
    @media (max-width: $screen-sm-max) {
        width: 300px;
        height: 300px;
        display: block;
        margin: 0 auto;
        padding: 40px;
    }
    @media (max-width: $screen-xs-max) {
        width: 280px;
        height: 280px;
        padding: 20px;
    }
    &__content {
        // position: relative;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        color: $white;
        left: 50%;
        width: 280px;
    }
    &__title {
        font-weight: $font-regular;
        margin: 0 0 30px;
        @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
            font-size: $font-md;
        }
        @media (max-width: $screen-xs-max) {
            margin: 0 0 10px;
        }
    }
    &__brief {
        font-weight: $font-light;
        font-size: $font-md;
        line-height: 1.36;
        @media (max-width: $screen-md-max) and (min-width: $screen-md-min) {
            font-size: $font-sm;
        }
        max-height: 195px;
        overflow: hidden;
    }
}

#ballsCanvas {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}