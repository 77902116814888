.squared-text {
    padding: 30px 55px;
    color: $white;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-orient: vertical;
    @media (max-width: $screen-sm-max) {
        padding: 50px;
    }
    @media (max-width: $screen-xs-max) {
        padding: 30px 20px;
    }
    &:not([class*=gbg-]) {
        padding: 20px 30px;
        @media (max-width: $screen-xs-max) {
            padding: 30px 0;
        }
    }
    &__data {
        position: relative;
        opacity: 0.9;
        padding: 30px 0;
        @media (min-width: $screen-sm-min) {
            margin-bottom: auto;
        }
    }
    &__title {
        margin: 0 0 25px;
        font-weight: $font-regular;
        word-break: break-word;
    }
    &__brief {
        font-weight: $font-light;
        font-size: $font-md;
        margin-bottom: 20px;
        line-height: 1.45;
        // max-height: 180px;
        overflow: hidden;
        @media (max-width: $screen-xs-max) {
            // max-height: 105px;
        }
    }
}

.black-ico {
    fill: #299be7;
    stroke: #299be7;
}

.blog-inner-arrows {
    margin-top: 25px;
}

.prev-arrow,
.next-arrow {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    .rtl & {
        float: left;
    }
}

.each-media {
    .slick-slide & {
        margin: 0 7.5px;
    }
}

.media-inner {
    padding-bottom: 30px;
    img {
        margin-bottom: 40px;
    }
}